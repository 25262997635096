<script setup>

</script>

<template>

<div  class="main-heading">
    <h1>AdminPage of the museum site</h1>
    <p>do not edit if you dont know what you're doing</p>
</div>

<div class="alternate-container">    
    <p>there will be options here</p>
</div>

</template>
