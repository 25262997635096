import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },

  {
    path: '/createarticle',
    name: 'createarticle',
    component: () => import('../views/CreateArticle.vue')
  },
  {
    path: '/createpicture',
    name: 'createpicture',
    component: () => import('../views/CreatePicture.vue')
  },
  {
    path: '/editarticle',
    name: 'editarticle',
    component: () => import('../views/EditArticle.vue')
  },
  {
    path: '/editarticle/:id',
    name: 'editarticleid',
    component: () => import('../views/EditArticleDetail.vue')
  },
  {
    path: '/editpicture',
    name: 'editpicture',
    component: () => import('../views/EditPicture.vue')
  },
  {
    path: '/editpicture/:id',
    name: 'editpictureid',
    component: () => import('../views/EditPictureDetail.vue')
  },



]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router

